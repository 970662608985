<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

export default {
  page: {
    title: "Users Table",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: { Layout, PageHeader, Multiselect, DatePicker },
  data() {
    return {
      isReportTableBusy: false,
      tableData: [],
      excelItems: [],
      title: "Users",
      disableUserIdentifier: true,
      items: [
        {
          text: "View"
        },
        {
          text: "Users",
          active: true
        }
      ],
      daterange: [],
      userType: "",
      userTypeArr: [
        {
          id: 1,
          value: "Normal User"
        },
        {
          id: 2,
          value: "Corporate User"
        }
      ],
      genders: ["Male", "Female", "Others"],
      principleBranches: [],
      corporates: [],
      corpData: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      tableTotal: "",
      tableTo: "",
      corpBranchID: "",
      cityArr: [],
      city: "",
      countryArr: [],
      country: "",
      branches: [],
      branchID: "",
      alertMsg: "",
      showQRImage: "",
      userName: "",
      corporate_user: "",
      isUpdating: false,
      showDismissibleAlert: false,
      fields: [
        {
          key: "Action",
          sortable: false
        },
        {
          key: "QrImage",
          sortable: false
        },
        {
          key: "userName",
          label: "Customer Name",
          sortable: true
        },
        {
          key: "employeeID",
          label: "Customer ID",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "emailID",
          sortable: true
        },
        {
          key: "mobileNo",
          sortable: true
        },
        {
          key: "corporateName",
          sortable: true
        },
        {
          key: "department",
          sortable: true
        },
        {
          key: "userType",
          sortable: true
        },
        {
          key: "DOB",
          sortable: true
        },
        {
          key: "anniversary",
          sortable: true
        },
        {
          key: "createdBy",
          sortable: true
        },
        {
          key: "created",
          sortable: true
        }
      ],
      userId: 0
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    if (
      this.$storageData.profile.empTypeID == 5 &&
      this.$storageData.login_type == 3
    ) {
      // corp brand login
      this.getCorporateCountry();
      this.corpBranchID = this.$storageData.profile.corpBranchID > 0 ? this.$storageData.profile.corpBranchID : undefined ;
    } else {
      // super admin login
      if (this.$route.params.id) {
        sessionStorage.setItem("corpBranchID", this.$route.params.id);
      } else if (this.$storageData.profile.corpBranchID!==undefined || this.$storageData.profile.corpBranchID > 0) {
        sessionStorage.setItem("corpBranchID", this.$storageData.profile.corpBranchID);
      } else {
        sessionStorage.setItem("corpBranchID", 0);
      }
      this.corpBranchID = sessionStorage.getItem("corpBranchID");
    }

    if (
      this.$storageData.login_type == 3 ||
      this.$storageData.profile.empTypeID == 5
    ) {
      this.corpBranchID = this.$storageData.profile.corpBranchID > 0 ? this.$storageData.profile.corpBranchID : undefined ;
    }
  },

  methods: {
    downloadSample() {
      this.axios
        .post(this.$loggedRole+"/exportExcel",
          {
            exportData: this.excelItems,
            page: "User Table"
          },
          {
            responseType: "blob"
          }
        )
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel"
            })
          );

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Customers.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },
    showModal(image, userName) {
      this.showQRImage = image;
      this.userName = userName;
      this.$root.$emit("bv::show::modal", "qrModal");
    },
    editUserProfile(user, id) {
      if (
        this.$storageData.profile.empTypeID == 0 ||
        !this.$storageData.profile.empTypeID ||
        this.$storageData.profile.empTypeID == "undefined"
      ) {
        alert("Can not process this request.");
        return;
      }
      this.userName = user;
      this.userId = id;
      let reqData = new FormData();
      reqData.append("employee_id", id);
      reqData.append("employee_type", this.$storageData.profile.empTypeID);
      this.axios
        .post(this.$loggedRole+"/get-corporate-employee-details", reqData)
        .then(response => {
          if (response.data.status == 200) {
            this.corporate_user = response.data.data;
            //Fetch all principle branches

            this.axios.post(this.$loggedRole+"/getAllPrincipleBranches", {}).then(res => {
              this.principleBranches = res.data.data;
            });

            //Fetch all corporates
            this.getCorporates(this.corporate_user.principle_branch_id);
            this.$root.$emit("bv::show::modal", "userProfileEdit");
          }
        })
        .catch(error => {
          alert(error.response.data.message);
        });
    },
    promptConfirmation() {
      if (
        this.disableUserIdentifier &&
        window.confirm(
          "Updating user identifier may affect user's wallet. Are you still want to update ?"
        )
      ) {
        this.disableUserIdentifier = false;
      }
    },
    getCorporates(principle_branch) {
      let corpReq = new FormData();
      corpReq.append(
        "principle_branch",
        principle_branch > 0 ? principle_branch : 0
      );
      this.axios
        .post(this.$loggedRole+"/get-corporates", corpReq)
        .then(res => {
          this.corporates = res.data.data;
        })
        .catch(error => {
          alert(error.response.data.message);
        });
    },
    updateUserCorporateCode(new_code) {
      this.corporate_user.corporateCode = new_code;
    },
    resetUserProfileModal() {
      this.disableUserIdentifier = true;
      this.userId = 0;
      this.corporate_user = "";
      this.principleBranches = [];
      this.corporates = [];
    },
    updateUser() {
      this.isUpdating = true;
      this.disableUserIdentifier = true;
      let userData = new FormData();
      userData.append("userName", this.corporate_user.userName);
      userData.append("emailID", this.corporate_user.emailID);
      userData.append("mobileNo", this.corporate_user.mobileNo);
      userData.append("corporateCode", this.corporate_user.corporateCode);
      userData.append("userIdentifer", this.corporate_user.userIdentifer);
      userData.append("DOB", this.corporate_user.DOB);
      userData.append("gender", this.corporate_user.gender);
      userData.append("anniversary", this.corporate_user.anniversary);
      userData.append("isTncAgreed", this.corporate_user.isTncAgreed);
      userData.append("is_consent_agree", this.corporate_user.is_consent_agree);
      userData.append("isEmailVerified", this.corporate_user.isEmailVerified);
      userData.append("isMobileVerified", this.corporate_user.isMobileVerified);
      userData.append(
        "corpBranchID",
        this.corporate_user.corporate
          ? this.corporate_user.corporate.corpBranchID
          : this.corporate_user.corpBranchID
      );
      userData.append(
        "principle_branch_id",
        this.corporate_user.principle_branch
          ? this.corporate_user.principle_branch.principleBranchID
          : this.corporate_user.principle_branch_id
      );
      userData.append("employee_type", this.$storageData.profile.empTypeID);
      userData.append("user_id", this.userId);
      userData.append("updated_by", this.$storageData.profile.pid);
      this.axios
        .post(this.$loggedRole+"/update-corporate-user", userData)
        .then(response => {
          this.isUpdating = false;
          this.$root.$emit("bv::hide::modal", "userProfileEdit");
          alert(response.data.message);
        })
        .catch(error => {
          this.isUpdating = false;
          alert(error.response.data.message);
        });
    },
    removeUser(userID) {
      this.axios.post(this.$loggedRole+"/removeUser", { userID: userID }).then(() => {
        this.showDismissibleAlert = true;
        this.alertMsg = "User Removed Successfully!";
        this.readUsersData();

        setTimeout(() => {
          this.showDismissibleAlert = false;
        }, 2000);
      });
    },
    getCorporateBranchByCorpID() {
      this.axios
        .post(this.$loggedRole+"/getCorporateBranchByCorpID", {
          corporateID: this.$storageData.profile.corporateID,
          city: this.city ? this.city.city : ""
        })
        .then(response => {
          this.branches = response.data.data;
          // this.readUsersData(this.branchID.corpBranchID);
        });
    },

    SWupdateUserList() {
      if (window.confirm("Are you sure to import employees?")) {
        this.axios
          .post(this.$loggedRole+"/swUserUpdate", {
            corporateID: this.$storageData.profile.corporateID,
            corpBranchID: this.$storageData.profile.corpBranchID,
            companyId: this.$storageData.profile.corporateIDentifierCode
          })
          .then(response => {
            if (response != null) {
              this.readUsersData();
            }
          });
      }
    },
    onchangeBranch() {
      this.corpBranchID = this.branchID.corpBranchID;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    readUsersData() {
      if (this.corpBranchID === null || this.corpBranchID.length == 0) {
        alert("Corporate branch required!");
      } else {
        this.isReportTableBusy = true;

        this.axios
          .post(this.$loggedRole+"/viewAllUsers?page=1", {
            corpBranchID: this.corpBranchID,
            flag: this.userType.id,
            daterange: this.daterange,
            perPage: this.perPage
          })
          .then(response => {
            //Then injecting the result to datatable parameters.
            this.isReportTableBusy = false;
            this.tableData = response.data;
            if (this.perPage != "All") {
              this.tableTotal = this.tableData.data.total;
              this.tableTo = this.tableData.data.to;
              this.tableItems = response.data.data.data;
              this.excelItems = response.data.userExcel;
            } else {
              this.tableTotal = 1;
              this.tableTo = 1;
              this.tableItems = response.data.data;
              this.excelItems = response.userExcel;
            }
          });
      }
    },
    readUsersDataPerPage(currentPage, corpBranchID) {
      this.isReportTableBusy = true;

      this.axios
        .post(this.$loggedRole+"/viewAllUsers?page=" + currentPage, {
          corpBranchID: corpBranchID,
          flag: this.userType.id,
          daterange: this.daterange,
          perPage: this.perPage
        })
        .then(response => {
          this.isReportTableBusy = false;
          //Then injecting the result to datatable parameters.
          // this.tableData = response.data.data;
          this.tableItems = response.data.data.data;
          this.excelItems = response.data.userExcel;
        });
    },
    // getCorporateDetailsByID(corpBranchID){
    //         this.axios
    //     .post(
    //       "getCorporateDetailsByID", {'corpBranchID':corpBranchID}
    //     )
    //     .then((response) => {
    //       //Then injecting the result to datatable parameters.
    //        this.corpData = response.data.data.corpBranchName;
    //     });
    // },
    addUserPage() {
      this.$router.push({
        name: "corporateUsersForm",
        params: { id: sessionStorage.getItem("corpBranchID") }
      });
    },
    getCorporateCountry() {
      this.axios
        .post(this.$loggedRole+"/getCorporateCountry", {
          corporateID: this.$storageData.profile.corporateID
        })
        .then(response => {
          this.countryArr = response.data.data;
          this.country = this.countryArr ? this.countryArr[0] : "";
          this.getCorporateCities();
        });
    },
    getCorporateCities() {
      this.axios
        .post(this.$loggedRole+"/getCorporateCities", {
          corporateID: this.$storageData.profile.corporateID,
          country: this.country ? this.country.country : ""
        })
        .then(response => {
          this.cityArr = response.data.data;
          this.city = this.cityArr ? this.cityArr[0] : "";
          this.getCorporateBranchByCorpID();
        });
    },
    applyFilter() {
      if (this.daterange.includes(null)) {
        this.daterange = [];
      }
      this.readUsersData();
    },
    clearFilter() {
      this.daterange = [];
      this.userType = "";
      // this.readUsersData(this.corpBranchID);
    }
  },
  watch: {
    currentPage: function(newVal) {
      var corpBranchID = this.corpBranchID;
      this.readUsersDataPerPage(newVal, corpBranchID);
      //  console.log('Change to page', newVal)
    }
  },
  middleware: "authentication"
};
</script>

<template>
  <Layout>
    <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" dismissible variant="success">
        {{ alertMsg }}
      </b-alert>
    </div>
    <PageHeader :items="items" />
    <!-- <PageHeader :title="title" :items="items" /> -->

    <!--FILTER START------>
    <div class="card" style="margin-top:-30px">
      <div class="card-body">
        <div class="inner mb-2 row">
          <!-- Country List-->
          <div v-if="this.$storageData.profile.empTypeID == 5" class="col-md-2">
            <label>Select Country </label>
            <multiselect
              v-model="country"
              :options="countryArr"
              :show-labels="false"
              label="country"
              track-by="country"
              @input="getCorporateCities()"
            ></multiselect>
          </div>
          <div v-if="this.$storageData.profile.empTypeID == 5" class="col-md-2">
            <label>Select City </label>
            <multiselect
              v-model="city"
              :options="cityArr"
              :show-labels="false"
              label="city"
              track-by="city"
              @input="getCorporateBranchByCorpID()"
            ></multiselect>
          </div>
          <div v-if="this.$storageData.profile.empTypeID == 5" class="col-md-3">
            <label>Select Corporate Branch </label>
            <multiselect
              v-model="branchID"
              :options="branches"
              :show-labels="false"
              label="corpBranchName"
              track-by="corpBranchID"
              @input="onchangeBranch()"
            ></multiselect>
          </div>
          <div
            v-if="
              this.$storageData.login_type == 1 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 9 ||
                this.$storageData.profile.empTypeID == 11
            "
            class="col-md-2"
            style="width:12%"
          >
            <label>User Type</label>
            <multiselect
              v-model="userType"
              :options="userTypeArr"
              :show-labels="false"
              label="value"
              track-by="id"
            ></multiselect>
            <!-- @input="readUsersData(corpBranchID);" -->
          </div>
          <!-- Country List END-->
          <!-- Date range -->
          <div class="col-md-2" style="width:20%">
            <label>Date Range </label>
            <date-picker
              v-model="daterange"
              append-to-body
              confirm
              format="DD MMM Y"
              lang="en"
              range
            ></date-picker>
            <!-- <date-picker v-model="daterange" format="DD MMM Y" range append-to-body lang="en" confirm @input="readUsersData(this.corpBranchID);"></date-picker> -->
          </div>
          <!-- Date range END-->
          <!-- Apply filter -->
        </div>
        <div class="row inner mb-2">
          <div class="col-md-2" style="width: auto;">
            <button class="btn btn-themeOrange mt-4" v-on:click="applyFilter()">
              Apply Filter
            </button>
          </div>
          <!-- Apply filter End -->
          <!-- Clear filter -->
          <div class="col-md-2" style="width:16%;">
            <button class="btn btn-themeBrown mt-4" v-on:click="clearFilter()">
              Clear Filter
            </button>
          </div>
          <!-- Clear filter END-->
        </div>
      </div>
    </div>
    <!--FILTER END------>

    <button
      v-if="this.corpBranchID > 0 && this.$storageData.login_type != 3"
      class="col-md-1 btn btn-themeYellow w-md waves-effect waves-light"
      style="position: absolute; right: 2%; top: 79px;"
      @click="addUserPage()"
    >
      Add User <i class="uil-user-plus"></i>
    </button>
    <div class="row" style="margin-top:30px;">
      <div class="col-12">
        <h6>{{ corpData }}</h6>
        <div class="card">
          <div class="card-body">
            <button
              v-if="this.$storageData.profile.corporateIDentifierCode != null"
              class="btn btn-themeBrown"
              type="button"
              @click="SWupdateUserList()"
            >
              Import employees
            </button>
            <div class="row mt-4">
              <div
                v-if="this.$storageData.login_type == 1"
                class="col-sm-12 col-md-4"
                style="margin-bottom:15px;"
              >
                <div class="btn-group" role="group">
                  <!-- <button type="button" class="btn btn-themeBrown">Excel</button> -->
                  <button
                    class="btn btn-themeBrown"
                    type="button"
                    @click="downloadSample()"
                  >
                    Excel
                  </button>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                      style="margin-left:5px; margin-right:5px"
                      @input="readUsersData()"
                    ></b-form-select>
                    &nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center"
                    >Search:
                    <b-form-input
                      v-model="filter"
                      class="form-control form-control-sm ms-2"
                      placeholder="Search..."
                      type="search"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 table-striped">
              <b-table
                :busy="isReportTableBusy"
                :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                :items="tableItems"
                :per-page="0"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                bordered
                hover
                outlined
                responsive
                striped
                @filtered="onFiltered"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template v-slot:cell(Action)="data">
                  <button
                    v-if="data.item.corpBranchID"
                    class="btn btn-themeOrange"
                    style="padding: 0px 7px;"
                    @click="removeUser(data.item.userID)"
                  >
                    Remove
                  </button>
                  <button
                    v-if="$storageData.profile.empTypeID == 1"
                    class="btn btn-themeYellow m-2"
                    style="padding: 0px 7px;"
                    @click="
                      editUserProfile(data.item.userName, data.item.userID)
                    "
                  >
                    Edit
                  </button>

                  <!-- <i class="uil uil-edit-alt" title="Edit" style="font-size: 19px;"></i>
                    <i class="mdi mdi-delete" title="Delete" style="font-size: 19px;"></i>
                    <router-link :to="{ name: 'corporateUsersTable', params: { id: data.item.corpBranchID} }">
                      <i class="fas fa-users" title="View Users"></i>
                  </router-link> -->
                </template>
                <template v-slot:cell(QrImage)="data">
                  <img
                    :src="data.item.QrImage"
                    style="height: 20px;"
                    @click="showModal(data.item.QrImage, data.item.userName)"
                  />
                </template>
                <template
                  v-if="this.$storageData.login_type != 3"
                  v-slot:cell(userName)="data"
                >
                  <router-link
                    :to="{
                      name: 'userProfile',
                      params: { id: data.item.userID }
                    }"
                  >
                    <span>
                      {{ data.item.userName }}
                    </span>
                  </router-link>
                </template>
                <template v-slot:cell(userType)="data">
                  <span v-if="data.item.corpBranchID">Corporate User</span>
                  <span v-else>Normal User</span>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <!-- <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination> -->
                    <b-pagination
                      v-model="currentPage"
                      :per-page="tableTo"
                      :total-rows="tableTotal"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal for finance  -->
    <b-modal id="qrModal" :title="this.userName" centered hide-footer>
      <div style="text-align:center;    margin-bottom: 21px;">
        <img :src="this.showQRImage" />
      </div>
    </b-modal>

    <b-modal
      id="userProfileEdit"
      :title="this.userName"
      centered
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
      @hidden="resetUserProfileModal"
    >
      <div class="row">
        <div class="col-md-4">
          <b-form-group
            id="input-group-1"
            label="User Name:"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="corporate_user.userName"
              placeholder="User Name"
              required
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group id="input-group-2" label="Email:" label-for="input-2">
            <b-form-input
              id="input-2"
              v-model="corporate_user.emailID"
              placeholder="Email"
              required
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group
            id="input-group-3"
            label="Mobile No.:"
            label-for="input-3"
          >
            <b-form-input
              id="input-3"
              v-model="corporate_user.mobileNo"
              placeholder="Mobile No."
              required
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-4">
          <b-form-group
            id="input-group-4"
            label="User Identifier:"
            label-for="input-4"
          >
            <b-form-input
              id="input-4"
              v-model="corporate_user.userIdentifer"
              :readonly="disableUserIdentifier"
              placeholder="User Identifier"
              required
              @click="promptConfirmation()"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group
            id="input-group-5"
            label="Employee ID:"
            label-for="input-5"
          >
            <b-form-input
              id="input-5"
              v-model="corporate_user.employeeID"
              placeholder="Employee ID"
              required
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group id="input-group-6" label="DOB :" label-for="input-6">
            <input
              v-model="corporate_user.DOB"
              class="form-control"
              type="date"
            />
          </b-form-group>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-4">
          <b-form-group
            id="input-group-7"
            label="Anniversary :"
            label-for="input-7"
          >
            <input
              v-model="corporate_user.anniversary"
              class="form-control"
              type="date"
            />
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group id="input-group-8" label="Gender :" label-for="input-8">
            <multiselect
              id="input-8"
              v-model="corporate_user.gender"
              :options="genders"
              :show-labels="false"
            ></multiselect>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group
            id="input-group-11"
            label="Corporate Code:"
            label-for="input-11"
          >
            <b-form-input
              id="input-11"
              v-model="corporate_user.corporateCode"
              placeholder="Corporate Code"
              required
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <label>Principle Branch :</label>
          <multiselect
            v-model="corporate_user.principle_branch"
            :options="principleBranches"
            :show-labels="false"
            label="principleBranchName"
            track-by="principleBranchID"
            @input="
              getCorporates(corporate_user.principle_branch.principleBranchID)
            "
          ></multiselect>
        </div>
        <div class="col-md-6">
          <label>Corporate Branch :</label>
          <multiselect
            id="input-10"
            v-model="corporate_user.corporate"
            :options="corporates"
            :show-labels="false"
            label="corporateName"
            track-by="corpBranchID"
            @input="
              updateUserCorporateCode(corporate_user.corporate.corporateCode)
            "
          ></multiselect>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-3">
          <b-form-checkbox
            id="checkbox-1"
            v-model="corporate_user.isTncAgreed"
            name="checkbox-1"
            unchecked-value="0"
            value="1"
          >
            &nbsp;&nbsp;Agreed CloudKitch T&C
          </b-form-checkbox>
        </div>
        <div class="col-md-3">
          <b-form-checkbox
            id="checkbox-2"
            v-model="corporate_user.is_consent_agree"
            name="checkbox-2"
            unchecked-value="0"
            value="1"
          >
            &nbsp;&nbsp;Agreed Consent
          </b-form-checkbox>
        </div>
        <div class="col-md-3">
          <b-form-checkbox
            id="checkbox-3"
            v-model="corporate_user.isEmailVerified"
            name="checkbox-3"
            unchecked-value="0"
            value="1"
          >
            &nbsp;&nbsp; Email Verified
          </b-form-checkbox>
        </div>
        <div class="col-md-3">
          <b-form-checkbox
            id="checkbox-4"
            v-model="corporate_user.isMobileVerified"
            name="checkbox-4"
            unchecked-value="0"
            value="1"
          >
            &nbsp;&nbsp; Mobile Verified
          </b-form-checkbox>
        </div>
      </div>
      <hr />
      <div class="row mt-3">
        <div class="col-md-2 offset-md-10">
          <button
            :disabled="isUpdating"
            class="btn btn-themeYellow w-md waves-effect waves-light"
            @click="updateUser()"
          >
            Submit
            <b-spinner
              v-if="isUpdating"
              class="align-middle text-danger"
            ></b-spinner>
          </button>
        </div>
      </div>
    </b-modal>
    <!-- end of finance  -->
  </Layout>
</template>

<style scoped>
.alertMsg {
  position: fixed;
  right: 0px;
  z-index: 500;
  top: 79px;
  width: 22%;
}
.page-link {
  min-width: 70px !important;
  width: 70px;
}
</style>
